<template>
    <div class="user-deposit">
        <!-- 标题 -->
        <div class="user-deposit-num">
            <span class="user-deposit-num-title">保证金:</span>
            <span class="user-deposit-num-price">1000</span>
        </div>
        <!-- 备注 -->
        <div class="user-deposit-remark">保证金在退出本平台服务后可以申请退还</div>
        <!-- 按钮 -->
        <div class="user-deposit-handle">
            <el-button
                type="warning"
                @click="handlePay">
                支付
            </el-button>
            <el-button
                type="success"
                @click="handleClose">
                已支付
            </el-button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    export default {
        computed: mapState({
            user_id: state => state.user.user_id
        }),
        methods: {
            ...mapMutations([
                'changeOutsideDialog'
            ]),
            handlePay() {
                let url = `${this.$_url_.baseUrl}payment/alipay?userID=${this.user_id}&type=${6}`;

                window.open(url);
            },
            handleClose() {
                this.changeOutsideDialog(false);
                this.$router.go(0);
            }
        }
    }
</script>

<style scoped>
.user-deposit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
}
.user-deposit-num-title{
    font-size: 16px;
    font-weight: bold;
}
.user-deposit-num-price{
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
}
.user-deposit-remark{
    margin-top: 10px;
    font-size: 12px;
    color: #909399;
}
.user-deposit-handle{
    margin-top: 20px;
}
</style>